<template>
  <div class="page">
    <van-nav-bar :title="fileInfo.name" left-arrow @click-left="onClickLeft">
    </van-nav-bar>
    <div class="c">
      <div
        class="file-item"
        v-for="(file, index) in fileInfo.files"
        :key="index"
      >
        <FileItem :file="file"></FileItem>
      </div>
    </div>
  </div>
</template>
<script>
import storage from "@/libs/storage";
export default {
  components: {
    FileItem: () => import("./components/FileItem"),
  },
  data() {
    return {
      fileInfo: storage.getSession(this.$route.query.storeId),
    };
  },
};
</script>
<style lang="less" scoped>
.page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .c {
    flex: 1;
    overflow-y: auto;

    .file-item {
      margin-bottom: 20px;
    }
  }
}
</style>